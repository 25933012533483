<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary">
                    <form role="form" @submit.prevent="submitForm">
                        <div class="invoice p-3 mb-3">
                            <input id="id" class="form-control" v-model="form.id" type="hidden" name="id" />
                                <div class="card card-default">
                                    <div class="card-header">
                                        <h3 class="card-title"></h3>
                                        <div class="card-tools">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">TANGGAL JURNAL</label>
                                                <datepicker id="date" v-model="form.date" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="submit" class="btn btn-primary mr-1" :disabled="processing" >Simpan</button>
                                        <router-link
                                        :to="{ path: '/report/jurnal-group' }"
                                        class="btn btn-secondary"> Kembali </router-link>
                                    </div>
                                </div>
                            <!-- /.card-body -->
                            <div class="p-2"></div>
                            <!-- form items -->
                            <h4 class="mb-2">
                                <i class="fa fa-tags"></i> &nbsp; List Setting Tutup Buku
                            </h4>
                            <table class="table table-hover" ref="tableitem" id="tableitem">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>TINDAKAN</th>
                                    </tr>
                                </thead>
                                <tbody @click="handleClick"></tbody>
                                <tfoot>
                                <tr>
                                <th></th>
                                <th></th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </form>
                    <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { createTable, authFetch, formatCurrency,auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import "vue-select/dist/vue-select.css";
import { VueEditor } from "vue2-editor";
import "icheck-material";
import moment from "moment";
import datepicker from "@/components/Datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "JurnalClose",
    data() {
        return {
            errors: [],
		    loadingContent: false,
            loading: false,
            roles: "",
            show: false,
            method: "POST",
            form: {
                date: moment().format("DD/MM/YYYY"),
            },
            submitting: false,
            processing: false,
        };
    },
    components: {
        datepicker,
    },
    created: function() {
        this.userInfo = auth.user();
        if (this.$route.params.id) {
            this.loadGroup(this.$route.params.id);
        } else {
        }
        $("#date").datepicker( "option", "disabled", true );
    },
    methods: {
        submitForm: function(ev) {
            const self = this;
            self.submitting = true;
            self.form.id = self.$route.params.id;
            // moment(self.form.journal_date).format("YYYY-MM-DD");
            // moment(self.form.date).format("YYYY-MM-DD");
            var urlSubmit = "/accounting/jurnal/close_jurnal";
            var setmethod = self.method;

            Swal.fire({
            title: "Simpan?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                this.processing = true;
                this.loadingContent = true;
                authFetch(urlSubmit, {
                    method: setmethod,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(self.form),
                })
                    .then((res) => {
                    return res.json();
                    })
                    .then((js) => {
                    if (js.success) {
                        Swal.fire("Proses Berhasil", ``, "success");
                        this.$router.push("/report/jurnal-group");
                        this.loadingContent = false;
                        this.processing = false;
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                        this.loadingContent = false;
                        this.processing = false;
                    }
                    self.submitting = false;
                    });
                }
            });

            ev.preventDefault();
        },
        handleClick(e) {
            const evt = this.$refs;
            if (e.target.closest("button")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "remove") {
                    Swal.fire({
                    title: "Simpan?",
                    showCancelButton: true,
                    confirmButtonText: `Ya`,
                    cancelButtonText: "Tidak",
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        console.log(result);
                        if (result.isConfirmed) {
                        this.processing = true;
                        this.loadingContent = true;
                        authFetch('/accounting/jurnal/close_jurnal', {
                            method: 'PUT',
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                        })
                            .then((res) => {
                            return res.json();
                            })
                            .then((js) => {
                            if (js.success) {
                                Swal.fire("Proses Berhasil", ``, "success");
                                var nRow = $(e.target).closest("tr").get(0);
                                this.tbl.fnDeleteRow(nRow);
                                this.loadingContent = false;
                                this.processing = false;
                            } else {
                                Swal.fire("Proses gagal", ``, "error");
                                this.loadingContent = false;
                                this.processing = false;
                            }
                            self.submitting = false;
                            });
                        }
                    });
                }
                return false;
            }
        },
    },
    mounted() {
        const e = this.$refs;
        const self = this;
        
        this.tbl = createTable(e.tableitem, {
            title: "",
            ajax: "/accounting/jurnal/close_jurnal",
            serverSide: false,
            responsive: true,
            paramData: function(d) {
                if (self.$route.params.id) {
                    d.group_id = self.$route.params.id;
                } else {
                    d.group_id = 0;
                }
            },
            selectedRowClass: "",
            frame: false,
            displayLength: 100,
            lengthChange: false,
            filter: false,
            // order: [[ 0, "asc" ]],
            sort: false,
            processing: true,
            language: {
                zeroRecords: "Tidak ada data.",
                processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
            columns: [
            { data: "value" },
        { data: "action",
        render: function (data, type, row, meta) {
            return `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-danger" data-action="remove"><i class="fas fa-trash" :disabled="processing"></i> Hapus</button></div>`;
        }, },
            ],
            rowCallback: function (row, data) {
            },
            footerCallback:function ( row, data, start, end, display ){
                var api = this.api(), data;
                // Total over this page
            },
            initComplete: function () {
                $('.loading-overlay').removeClass('show');
            },
            }).fnEditor({
                action: '<div class="btn-group"><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Reset</a></div>',
            });
        },
}
</script>